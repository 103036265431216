.navbar-container {
  background: #121b22;
  color: white;
}

.navBar {
  background: #00151d;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-logo {
  margin-left: 5px;
  width: 180px;
  display: flex;
  align-items: center;
  gap: 10px;

  img {
    width: 150px;
  }
}

.dash-txt {
  font-family: 'Montserrat', sans-serif;
  color: #53badb;
  font-size: 32px;
  font-weight: 300;
  letter-spacing: 1px;
}

.btn {
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  height: 48px;
  margin-right: 15px;
  border-radius: 10px;
  transition: all 0.5s ease 0s;
  border: none;
  font-weight: 500;
  background-color: inherit;
  color: #60767e;
}

.btn-create {
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  height: 48px;
  margin-right: 15px;
  width: 140px;
  background-color: #ff4200;
  border-radius: 10px;
  border: none;
  color: #000;
}
