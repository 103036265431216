.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
}

.modal-content {
  background: #142127;
  color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 500px;
  position: relative;
}

.user-details {
  display: flex;
  gap: 10px;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
}

.profile-picture {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  cursor: pointer;

  img {
    width: 56px;
    height: 56px;
    object-fit: cover;
    border-radius: 50%;
  }

  .upload-photo {
    width: 56px;
    height: 56px;
    background: linear-gradient(135deg, #00151d, #004466);
    color: white;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  p {
    color: #60767e;
    font-size: 9px;
    margin-top: 12px;
    font-weight: 700;
  }
}

.form-group {
  margin-bottom: 15px;
  width: 100%;
}

.form-group label {
  float: left;
  margin: 6px 40px;
  font-size: 13px;
  font-weight: 500;
}

.form-group input {
  width: 300px;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #004466;
  background: inherit;
  color: white;
}

.btn-add-new-user {
  background: #ff6600;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  float: right;
  margin-right: 40px;

  &:disabled {
    background-color: #d67246;
    cursor: not-allowed;
    color: black;
  }
}

.member-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  overflow: auto;
  height: 96px;
}

.user-client-modal {
  background: #142127;
  color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 350px;
  height: 450px;
  position: relative;
}

.user-filter {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px;
  gap: 12px;

  input {
    width: 100%;
    border: 1px solid #004466;
    background: inherit;
    padding: 8px;
    border-radius: 5px;
    color: #60767e;
  }

  input::placeholder {
    color: #60767e;
  }

  button {
    padding: 8px 25px;
    background: inherit;
    border: 1px solid #60767e;
    border-radius: 5px;
    color: #60767e;
    font-weight: 600;
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
    }
  }

  .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    background-color: #2e3c49;
    // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 10;
    margin-top: 5px;
    padding: 0;
    list-style: none;
    max-width: 100%;
  }

  .dropdown-item {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.2s;
  }

  .dropdown-item:hover {
    background-color: #53badb;
  }

  .dropdown-item .user-info {
    display: flex;
    flex-direction: column;
  }

  .dropdown-item .user-details {
    font-size: 14px;
    color: #fff;
  }
}

.client-list {
  margin: 10px;
  background: inherit;
  height: 170px;
  overflow: auto;

  p {
    color: #60767e;
    font-size: 13px;
    font-weight: 600;
  }

  .client-item {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 20px 0;

    input[type='checkbox'] {
      width: 18px;
      height: 18px;
      border: 1px solid #004466;
      border-radius: 4px;
      background-color: transparent;
      appearance: none;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
    }

    input[type='checkbox']:checked {
      border: 2px solid #53badb;
    }

    input[type='checkbox']::after {
      content: '';
      position: absolute;
      width: 6px;
      height: 12px;
      border: solid #53badb;
      // border-radius: 2px;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      display: none;
    }

    input[type='checkbox']:checked::after {
      display: block;
    }

    span {
      font-size: 16px;
      font-weight: 500;
    }
  }
}
