.dropdown-container {
  display: flex;
  align-items: center;
  background: linear-gradient(135deg, #142127, #314d5a);
  border: 1px solid #00151d;
  border-radius: 5px;
  gap: 8px;
  cursor: pointer;
  width: 135px;
}

.sort-icon {
  width: 14px;
  height: 14px;
}

.dropdown {
  position: relative;
  width: 100%;
}

.dropdown-selected {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  color: white;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 5px;
}

.arrow-icon {
  width: 10px;
  height: 10px;
}

.dropdown-options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #2e3c49;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;
  max-height: 200px;
  overflow-y: auto;
}

.dropdown-option {
  padding: 10px;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #53badb;
    color: black;
  }
}

.toggle-btn {
  background: linear-gradient(135deg, #142127, #314d5a);
  border: 1px solid #00151d;
  border-radius: 5px;
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s;

  img {
    width: 18px;
    height: 18px;
  }

  &:hover {
    background-color: #334155;
  }
}
