.client-container {
  display: flex;
  flex-wrap: wrap;
  gap: 95px;
  margin-left: 50px;
  font-size: 15px;
  font-weight: 500;

  .client-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .client-avatar {
    width: 56px;
    height: 56px;
    background: linear-gradient(135deg, #142127, #314d5a);
    color: white;
    font-size: 25px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-bottom: 10px;
  }

  .project-count {
    font-size: 10px;
    margin-top: 4px;
    font-weight: normal;
  }
}

.display-control-container {
  display: flex;
  justify-content: flex-end;
  margin: 40px;
  gap: 20px;
}

.btn-add {
  color: #ff4200;
  font-size: 16px;
  font-weight: 600;
  border: none;
  background: inherit;
  cursor: pointer;
}

.control-container {
  display: flex;
  justify-content: space-between;
  margin: 40px 40px 40px 20px;

  .btn-container {
    display: flex;
    gap: 10px;
  }
}

.members-table {
  width: 100%;
  border-collapse: collapse;
  margin-left: 30px;
  font-weight: 400;
  font-size: 13px;
  font-family: 'Montserrat', sans-serif;
  max-height: 500px;
  overflow: auto;
}

.members-table th {
  padding: 10px;
  text-align: left;
  color: #60767e;
}

tbody tr:hover {
  background-color: #004466;
}
.members-table td {
  padding: 10px;
  text-align: left;
  font-weight: 500;
  cursor: pointer;
}

.add-client-btn {
  color: #ff4200;
  font-weight: 600;
  background: none;
  border: none;
  cursor: pointer;
}

.member-container {
  font-family: 'Montserrat', sans-serif;
  padding: 20px 40px;
  height: 65vh;
  overflow: auto;

  .profile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    box-shadow: 0 1px 0 0 rgba(96, 118, 126, 0.3);

    .profile-picture {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px;
      box-shadow: 1px 0 0 0 rgba(96, 118, 126, 0.3);

      .avatar {
        width: 56px;
        height: 56px;
        background: linear-gradient(135deg, #00151d, #004466);
        color: white;
        font-size: 18px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }

      p {
        color: #60767e;
        font-size: 9px;
        margin-top: 12px;
        font-weight: 700;
      }
    }

    .profile-info {
      display: flex;
      flex-direction: column;
      padding: 20px;
      gap: 9px;
      p {
        color: #60767e;
        font-size: 15px;
        font-weight: 700;
        margin: 0px;
      }
      div {
        font-size: 16px;
        font-weight: bold;
        margin: 0px;
      }
    }
  }

  .member-details {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    .member-email {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 40px;
      box-shadow: 0 1px 0 0 rgba(96, 118, 126, 0.3);

      p {
        color: #60767e;
        font-size: 15px;
        font-weight: 700;
      }

      div {
        font-size: 16px;
      }
    }

    .member-clients-list {
      display: flex;
      flex-direction: column;
      padding: 20px 0;

      .wrapper {
        display: flex;
        justify-content: space-between;
      }

      div {
        font-size: 15px;
        font-weight: 700;
        margin: 0px;
      }

      div {
        font-size: 16px;
        margin: 0px;
      }
    }
  }
}

.bottom-list-container {
  .client {
    display: flex;
    align-items: center;
    gap: 30px;
    padding: 25px 0;
    justify-content: space-between;
    box-shadow: 0 1px 0 0 rgba(96, 118, 126, 0.3);

    .client-info {
      color: white;
      display: flex;
      align-items: center;
      gap: 30px;
    }

    p {
      margin: 0;
    }
  }

  .avatar {
    width: 56px;
    height: 56px;
    background: linear-gradient(135deg, #00151d, #004466);
    color: white;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
}

.remove-btn {
  border-radius: 4px;
  background: inherit;
  border: 1px solid #60767e;
  color: #60767e;
  padding: 8px;
  cursor: pointer;
}

.btn-edit {
  border-radius: 4px;
  background: inherit;
  border: 1px solid #60767e;
  color: #60767e;
  padding: 6px 25px;
  cursor: pointer;
}
