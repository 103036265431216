.user-panel {
  display: flex;
  flex-direction: column;
  background-color: #142127;
  width: 300px;
  height: auto;

  &__info {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 35px 0 20px 35px;
  }

  &__avatar {
    width: 56px;
    height: 56px;
    background: linear-gradient(135deg, #00151d, #004466);
    color: white;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  &__details {
    display: flex;
    flex-direction: column;
    gap: 3px;
    padding: 10px;
  }

  &__name {
    font-size: 18px;
    font-weight: 600;
  }

  &__role {
    font-size: 13px;
    font-weight: 400;
  }

  &__count {
    font-size: 13px;
    font-weight: 300;
  }

  &__nav {
    margin-left: 0;
    span,
    button {
      display: block;
      padding: 13px 0;
      border: none;
      background: inherit;
      color: white;
      font-size: 16px;
      font-weight: 600;
      text-align: left;
      padding-left: 35px;
    }

    .active span {
      background-color: #53badb;
      color: #000000;
      cursor: pointer;
    }

    span:hover {
      background-color: #53badb;
      color: #000000;
      cursor: pointer;
    }

    button:hover {
      background-color: #53badb;
      color: #ff4200;
      width: 100%;
      cursor: pointer;
    }

    .nav-element-container {
      height: 73vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    button {
      color: #ff4200;
    }
  }
}
