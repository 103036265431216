.dashboard-container {
  background: #121b22;
  color: white;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.panel-container {
  display: flex;
  flex-grow: 1;
}

.detail-panel {
  flex-grow: 1;
  background-color: #00151d;
}

.tab-name-container {
  background: linear-gradient(135deg, #142127, #314d5a);
  color: white;
  font-size: 25px;
  font-weight: bold;
  padding: 15px 20px;
  margin: 35px 10px 10px 20px;
  border-radius: 8px;
  display: flex;
  align-items: end;
  justify-content: start;
  width: 92%;
  min-height: 75px;

  .path {
    font-size: 13px;
  }
}
